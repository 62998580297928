<template>
  <div class="view-wrapper">
    <v-container fluid pa-0 class="custom-card-questionnaire fill-parent-height">
      <v-card class="align-center">
        <v-card-title>
          Страница профиля заёмщика
        </v-card-title>
        <v-card-text style="width: 25%;">
          <v-row class="align-center">
            <v-btn color="success" @click="openAgreement">
              Открыть пользовательское соглашение
            </v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "StartupProfile",
  data : () => ({

  }),

  methods: {
    ...mapActions('app', ['setShowAgreement']),

    openAgreement(){
      this.setShowAgreement(true)
    }
  }
}
</script>
